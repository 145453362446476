import { createTheme } from '@mui/material/styles';
import { blue, red } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#f7e332',
      contrastText: '#B30007',
    },
    secondary: {
      main: '#f7e332',
    },
    yb: {
      main: '#000',
      background: "#f7e332",
      secondary: blue[400],
      contrastText: '#fff',
    },
    neutral: {
      main: '#B30007',
      contrastText: '#fff',
    },
    black:{
      main: '#222222',
      contrastText: '#f7e332',
    },
    label:{
      main: '#222222',
      sub: '#B30007',
    },
    error: {
      main: red.A400,
    },
    link:{
      main: blue[600],
      secondary: blue[400]
    }
  },
});

export default theme;
