import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: [],
    reducers: {
        addToCart: (state, action) => {

            const itemExists = state.find((item) => item.id === action.payload.id);
            if (itemExists) {
                itemExists.quantity+= parseFloat(action.payload.selected_quantity);
            } else {
                state.push({ ...action.payload, quantity: parseFloat(action.payload.selected_quantity) });
            }
        },
        incrementQuantity: (state, action) => {
            const item = state.find((item) => item.id === action.payload);
            item.quantity++;
        },
        decrementQuantity: (state, action) => {
            const item = state.find((item) => item.id === action.payload);
            if (item.quantity === 1) {
                const index = state.findIndex((item) => item.id === action.payload);
                state.splice(index, 1);
            } else {
                item.quantity--;
            }
        },
        removeFromCart: (state, action) => {
            const index = state.findIndex((item) => item.id === action.payload);
            state.splice(index, 1);
        },
        removeAllCart: (state) => {
            return [];
        }
    },
});

export const cartReducer = cartSlice;

export const {
  addToCart,
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
  removeAllCart,
} = cartSlice.actions;
