import { configureStore } from '@reduxjs/toolkit';
import { bookInfoReducer } from './book.slice';
import { cartReducer }  from './cart.slice';
import { loadState, loadBookingInfoState } from './localStorage';

// const reducer = {
//   cart: cartReducer,
// };
//
// const store = configureStore({
//     reducer: {
//         reducer,
//     }
// });


let cart = loadState();
let bkInfo = loadBookingInfoState();

const preloadedState = {...cart, bkInfo};

const store = configureStore({
      reducer: {
        cart: cartReducer.reducer,
        bkInfo : bookInfoReducer.reducer,
    },
    preloadedState,
});
store.subscribe(() => {
    const state = store.getState();
    let cart = store.getState().cart;
    let bkInfo = store.getState().bkInfo;
    localStorage.setItem('rxState', JSON.stringify({ cart: cart }));
    localStorage.setItem('bkState', JSON.stringify(bkInfo));
});

export default store;
